footer {
  min-width: 264px;
    display: grid;
    grid-template-rows: 25% 1fr;
    grid-template-columns: 1fr;
    background-color:#050A30 ;
    background-image:linear-gradient(#050a30e6, #050a30b6), url(../imagenes/Recursos/patron3.png) ;
    color: white;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 200px;
    margin-top: 50px;
  }
  /*titulo*/
  .titulo{
    display: flex;
    position: relative;
    grid-row: 1/2;
    grid-column: 1/2;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
  }
  .titulo::after{
    content: "";
    position: absolute;
    border-bottom: 2px solid white;
    height: 10%;
    width: 90%;
    border-radius: 20%;
    bottom: 0;
    margin: 0 auto;
  }
  /*Lista footer es el contenedor*/
  .lista{
    grid-row: 2/3;
    grid-column: 1/2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .contacto,
  .visita-contenedor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .a-footer {
    font-size: 2.5em;
    text-decoration: none;
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .h1-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  Footer h1 {
    font-size: 2.5rem;
    color: white;
  }
  /*icono*/
  .figure__img{
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
  .img-icon {
    height: 50px;
  }
  
  /* Media query para resoluciones más pequeñas */
  
  @media screen and (max-width: 600px) {
    footer{
      height: 400px;
    }
    .a-footer{
      font-size: 2.5rem;
    }
    .lista{
      display: flex;
      flex-direction: column;
    }
  
    .contacto,
    .visita-contenedor {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width:350px) {
    .a-footer{
      font-size: 1.9rem;
    }
  }