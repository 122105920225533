*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html{
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body{
  min-width: 264px;
  position: relative;
  background-image: url(/src/imagenes/Recursos/patron2.png);
  
}
.content{
  /* border: 5px solid red; */
  width:100%;
  height: auto;
  display: grid;
  grid-template-columns: 2fr 0.5fr;
  grid-template-rows: auto auto 2fr;
}
.articulo-intro{
  /* border: 5px solid chocolate; */
  grid-row: 2/3;
  grid-column: 1/3;
}
.container{
  /* border: 5px solid chartreuse; */
  grid-column: 1/2;
  grid-row: 3/4;
 
}
.publicidad__arriba{
  /* border: 5px solid purple; */
  grid-row: 1/2;
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  overflow: hidden;
}
.publicidad__costado{
  /* border: 5px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width:650px) {
  .content{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "pubArriba"
                          "introduccion"
                          "contenido"
                          "publicadaCostado";
  }
    .publicidad__arriba{
      grid-column: 1/2;
      grid-area: "pubArriba";
    }  
      .articulo-intro{
        grid-column: 1/2;
        grid-area: "introduccion";
      }
      .container{
        grid-column: 1/2;
        grid-area: "contenido";
      }
      .publicidad__costado{
        grid-column: 1/2;
        grid-area: "publicadaCostado";
        align-items: normal;
      }
}
