header{
    position: relative;
    top: 0;
    width: 100%;
    background-color:#1a1a1a;
    box-shadow: 0px 15px 10px -15px rgb(42, 42, 48);
    padding: 1em;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
header a{
    text-decoration: none;
    display: inline;
    color: rgb(255, 255, 255);
}
.titulo-contenedor{
    display: flex;
    width: 50%;
    justify-content: center;
}
header  .logo-div{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}
.hedear-a{
    margin-left: 10px;
    margin-right: 50px;
}

header .barra-menu{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
}
header .Nombre{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: 'Times new Roman';
}
.hedear-a img{
    width: 55px;
    height: 55px;
}
/*--------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/*Menu deslizante*/
.menu-contenedor-nav{
    border-top: 2px solid rgb(255, 255, 255); 
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 0vh;
    opacity: 0;
    overflow: hidden;
    transition: all 0.9s ease;
}
.menu-contenedor-nav.Activo{
    height: auto;
    opacity: 1;
}
.menu-contenedor-nav ul{
    list-style: none;
    flex-direction: column;
}
.menu-contenedor-nav li{
    margin: 0.5em 0;
}
.menu-contenedor-nav ul li:hover{
    box-shadow: 0 0 9px rgb(0, 0, 0);

}

/*-----------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/*Menu hamburguesa*/
.menu-contenedor{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
header .barra-menu span{
    display: block;
    width: 100%;
    height: 2px;
    background: rgb(207, 207, 207);
    margin-top: 6px;
    border-radius:10px;
    transform-origin: 0px 100%;
    transition: all 600ms;
}
 .activeline1-barra-menu{
    transform: rotate(45deg) translate(-2px, 1px);
}
.activeline2-barra-menu{
    opacity: 0;
    margin-left: -30px;

}
.activeline3-barra-menu{
    transform: rotate(-45deg) translate(-4px, 2px);
}
/*------------------------------------------------------------------------------------------------------------------------------------------------*/
@media(min-width:600px){

    header .menu-contenedor{
        display: none;
    }
    header .Nombre{
        font-size: 27px;
    }
    .menu-contenedor-nav{
        width: 50%;
        height: auto;
        opacity: 1;
        border: none;
    }
    .menu-contenedor-nav ul{
        width: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .menu-contenedor-nav ul li{
        margin-right:20px ;
    }
    .menu-contenedor-nav ul li:hover,a:hover{
        box-shadow: none;
        transform: scale(1.1);
        color: #8769ab;
    }
   
}
@media(min-width:750px){
    header .Nombre{
        font-size: 28px;
    }
    .menu-contenedor-nav{
        font-size: 26px;
    }
}
@media(min-width:820px){
    header .Nombre{
        font-size: 30px;
    }
    .menu-contenedor-nav{
        font-size: 27px;
    }
    
}