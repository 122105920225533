/*-------------------------Articulo de introduccion--------------------------*/
.articuloIntro-contenedor{
    width: 100%;
    height: auto;
    padding: 15px;
    display: grid;
    background-image: linear-gradient(180deg, rgba(12, 12, 56, 0.34) 0%, rgba(23, 31, 97, 0.623) 100%),url(../imagenes/Recursos/finanza-min.jpg);
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}
.articuloIntro-contenedor a{
  color: aqua;
}
.articuloIntro-contenedor a:visited{
  color: aqua;
}
.div-texto{
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}
.div-texto p{
  color: white;
  height: 100%;
  text-shadow: 0 0 20px rgb(0,0,0);
}
.articuloIntro-contenedor h1{
  font-size: 2.6rem;
  color: white;
  text-shadow: 0 0 20px rgb(0,0,0);
}
.articuloIntro-contenedor .div-titulo{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*------------------------------Tarjeta donde estan los articulos de crypto y movil-------------------------------------------------------*/
.articulo-contenedor{
    width: 95%;
    height: auto;
    padding: 15px;
    display: grid;
    box-shadow: 0px 0px 6px #dfdfdf;
    margin: 20px auto;

  }
  .articulo-contenedor .div-titulo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .articulo-contenedor .a-titulo{
    text-decoration: none; 
    justify-content: center;
  }
   .articulo-contenedor h1{
    font-size: 2.6rem;
    color: #444;
    padding-bottom: 10px;
  }
   p{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: block;
    font-size: 1.6rem;
    text-align:justify;
    line-height: 26px;
  }
  @media screen and (max-width:600px) {
    .div-texto{
      overflow: hidden;
      width: 100%;
    }
    .div-texto p{
      font-size: 1.6rem;
      text-align: center;
    }
    .div-titulo h1{
      text-align: center;
      font-size: 2rem;
    }

  }
  @media screen and (min-width:750px) {
    .articuloIntro-contenedor{
      height: 500px;
    }
  }