/*----------------------------------Tarjeta de inicio Blog------------------------------*/
.blogTarjetaDiv{
    border: 10px solid black;
    width: 100%;
    height: 120px;
}
/*---------------------------------------------------------------------------*/
.tituloBlog-div{
    width: 100%;
}
.blog-h1{
    font-size: 32px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.linea-sombreada{
    box-shadow: 0px 15px 10px -15px rgb(42, 42, 48);
}
.blog-autor-div{
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.time-blog{
    display: flex;
    align-items: center;
    font-size: 15px;
}

/*-------------------Imagen Blog------------------------------------*/
.imagenBlog-div{
    margin-top: 1%;
    width: 100%;
    height: auto;
}
.imagen-blog{
    width: 100%;
    height: 100%;
}
/*-----Cuerpo Blog----------*/
.contenidoBlog-div{
    margin-top: 25px;
    padding: 25px;
}
.contenidoBlog-div h2{
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 30px;
}
.contenidoBlog-div ul,ol li{
    font-size: 18px;
      line-height: 1.5;
}
p{
    font-size: 18px;
    color: black;
}
.seccionIntro{
    margin-bottom: 2%;
}
.seccionIntro p{
    font-weight: 600;
}
.seccionNormal{
    margin-bottom:2%;
}
.seccionLista{

    margin-bottom: 2%;
}
.seccionLista ul,ol li{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.seccionLista ul,ol{
    margin-left: 2%;
    padding: 20px;
}
/*--------------------------Tarjeta Blog Home-----------------------------------*/
.BlogTarjeta-div{
    border: 1px solid black;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 5%;
}
/*---------------------------Imagen blog secundaria----------------------------------*/
.div-img-sec{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.imagen-blog-sec{
    width: 30%;
    border-radius: 5px;
}